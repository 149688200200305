import {Menu, MenuButton, MenuItems, MenuItem} from '@headlessui/react';
import clsx from 'clsx';
import type {BaseComponentProps} from '@/components/types';
import type {FC, MouseEventHandler} from 'react';
import LivanLink from '@/components/core/LivanLink';

export type DropdownMenuItem = {
  label: string;
  // styleType?: StyleType;
} & XOR<
  {
    to: string;
  },
  {
    onClick: MouseEventHandler<HTMLAnchorElement>;
  }
>;

interface Props extends BaseComponentProps {
  items: DropdownMenuItem[];
}

const DropdownMenu: FC<Props> = function DropdownMenu(props) {
  const {className, items, children} = props;

  return (
    <Menu
      as="div"
      className={clsx(className, 'DropdownMenu relative')}
    >
      <MenuButton
        className="flex items-center"
        aria-haspopup="menu"
        aria-controls="dropdown-menu"
      >
        {children}
      </MenuButton>
      <MenuItems
        id="dropdown-menu"
        transition
        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {items.map((item) => {
          const {label, onClick, to} = item;
          return (
            <MenuItem key={label}>
              <LivanLink
                role="menuitem"
                to={to}
                type="unstyled"
                className="block px-4 py-2 data-[focus]:bg-gray-100"
                onClick={onClick}
                prefetch="viewport"
              >
                {item.label}
              </LivanLink>
            </MenuItem>
          );
        })}
      </MenuItems>
    </Menu>
  );
};

export default DropdownMenu;
