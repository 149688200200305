import LivanLink from '@/components/core/LivanLink';
import {boardsSignalsByOrgIdStore, userOrgsSignal} from '@/context/UserContext';
import {PlusIcon, Squares2X2Icon} from '@heroicons/react/24/outline';
import {useLocation} from '@remix-run/react';
import type {MouseEventHandler} from 'react';

import {useSignals} from '@preact/signals-react/runtime';
import clsx from 'clsx';
import {Transition} from '@headlessui/react';
import type {Board} from '@/model/board/types';
import type {OrgId} from '@/model/org/types';

export type MainNavItem = {
  id: string;
  name: string;
  path?: string;
  getSubItems?: () => SubNavItem[] | undefined;
  Icon?: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
};

type SubNavItem = Pick<MainNavItem, 'id' | 'name' | 'path' | 'Icon'>;

interface MainNavSectionProps {
  className?: string;
  items: MainNavItem[];
  onItemClick?: MouseEventHandler;
}

export function MainNavSection(props: MainNavSectionProps) {
  const {items, className, onItemClick} = props;
  return (
    <ul
      role="menu"
      className={clsx('-mx-2 space-y-1', className)}
    >
      {items.map((item) => {
        return (
          <MainNavSectionItem
            key={item.id}
            item={item}
            onClick={onItemClick}
          />
        );
      })}
    </ul>
  );
}

interface MainNavSectionItemProps {
  item: MainNavItem;
  onClick?: MouseEventHandler;
}

function MainNavSectionItem(props: MainNavSectionItemProps) {
  useSignals();
  const location = useLocation();
  const {item, onClick} = props;
  const {id, name, path, Icon, getSubItems} = item;
  const isCurrent = item.path === location.pathname;
  const isParentCurrent = !!item.path && location.pathname.startsWith(item.path);
  const subItems = getSubItems?.() || [];
  return (
    <li key={id}>
      <LivanLink
        type="unstyled"
        role="menuitem"
        to={path}
        className={clsx(
          isCurrent ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-900 hover:text-white',
          'group flex gap-x-3 rounded-md p-2 font-semibold leading-6',
        )}
        onClick={onClick}
      >
        {Icon ? (
          <Icon
            aria-hidden="true"
            className="h-6 w-6 shrink-0"
          />
        ) : (
          <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-900 font-medium text-gray-400 group-hover:text-white">
            {name[0]}
          </span>
        )}
        <span className="truncate">{name}</span>
      </LivanLink>
      <Transition show={isParentCurrent}>
        {subItems && (
          <ul
            style={{
              // TODO for some reason, headless UI does not animate out properly with fixed style attr..?
              // potentially related? https://github.com/tailwindlabs/headlessui/issues/3479
              height: isParentCurrent ? subItems.length * 40 : 0,
            }}
            className={clsx(
              'ml-3',
              'transition-[height] duration-300 overflow-y-hidden',
              'data-[closed]:!h-0',
            )}
          >
            {subItems.map((subItem) => {
              const {id, name, path, Icon} = subItem;
              const isCurrent = path === location.pathname;
              const Component = path ? LivanLink : 'div';
              const IconComponent = Icon || 'div';
              return (
                <li key={id}>
                  <Component
                    type="unstyled"
                    role="menuitem"
                    to={path}
                    className={clsx(
                      path
                        ? isCurrent
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-400 hover:bg-gray-900 hover:text-white'
                        : 'text-gray-400',
                      'group flex gap-x-3 rounded-md p-2 font-semibold leading-6 items-center',
                    )}
                    onClick={path ? onClick : undefined}
                  >
                    <IconComponent
                      aria-hidden="true"
                      className="h-3 w-3 shrink-0"
                    />
                    <span className="truncate">{name}</span>
                  </Component>
                </li>
              );
            })}
          </ul>
        )}
      </Transition>
    </li>
  );
}

function convertBoardToSubItem(orgId: OrgId, board: Board) {
  return {
    id: board.id,
    name: board.name,
    path: `/orgs/${orgId}/boards/${board.id}`,
    Icon: Squares2X2Icon,
  };
}

export function OrgNavSection(props) {
  useSignals();
  const {className} = props;
  const orgs = userOrgsSignal.value;
  if (orgs == undefined) {
    // user not logged in
    return null;
  }
  const items: MainNavItem[] = orgs.map((org) => {
    const {id, name} = org;
    return {
      id,
      name,
      path: `/orgs/${id}`,
      getSubItems() {
        const boardSignalsSignal = boardsSignalsByOrgIdStore.getById({id});
        if (boardSignalsSignal.value) {
          const publishedBoards: Board[] = [];
          const unpublishedBoards: Board[] = [];
          for (let i = 0; i < boardSignalsSignal.value.length; i++) {
            const board = boardSignalsSignal.value[i].value;
            if (board.publishedAt) {
              publishedBoards.push(board);
            } else {
              unpublishedBoards.push(board);
            }
          }

          const subItems: MainNavItem[] = [];
          if (unpublishedBoards.length) {
            subItems.push(
              {
                id: `${id}-unpublished-header`,
                name: 'Unpublished',
              },
              ...unpublishedBoards.map((board) => {
                return convertBoardToSubItem(id, board);
              }),
            );
          }
          if (publishedBoards.length) {
            subItems.push(
              {
                id: `${id}-published-header`,
                name: 'Published',
              },
              ...publishedBoards.map((board) => {
                return convertBoardToSubItem(id, board);
              }),
            );
          }
          return subItems;
        }
      },
    };
  });
  items.push({
    id: '£create', // use weird symbol to lower likelihood that these conflict
    name: 'Create an org',
    path: '/orgs/create',
    Icon: PlusIcon,
  });

  return (
    <MainNavSection
      {...props}
      items={items}
    />
  );
}
